/* Google Analytics */
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-55241421-1', 'auto');
ga('send', 'pageview');



// Add browser name as class to body
function get_browser() {
    var ua = navigator.userAgent,
        tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem =/\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE';
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/)
        if (tem != null) {
            return 'Opera'
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) !=null) {
        M.splice(1,1,tem[1]);
    }
    return M[0];
}

var browser = get_browser().toLowerCase();
document.body.className += " "+browser;


/* select2  functions for formatting brokerage
* -> formatBrokrage -> for dropdown options
* -> formatBrokerageSelection -> for current selected item
* */
function formatBrokerage (data) {

    if (data.loading) return data.text;

    var markup = '<div class="img-wrapper"><img src="' + data.photo +'" ></div><h5>' + data.title + '</h5><br><span>' + data.description + '</span><div class="clearfix"></div>';

    return markup;

};

function formatBrokerageSelection (data) {

     if(data.title)
     {
         var markup = '<div class="img-wrapper"><img src="' + data.photo +'" ></div><h5>' + data.title + '</h5><br>';
     }
     else
     {
         var markup = data.text
     }

     return markup;
};

/* end select2 functions */



var delay = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();


$(window).resize(function() {
    delay(function(){
        setBannerHeight();
    }, 100);
});


// SET HOME BANNER HEIGHT 
function setBannerHeight() {
    if($(window).width() > 1023){
        $(".banner").css({minHeight: $(window).height() - $(".navbar-top").height() - 1 +"px" });    
    }
    else{
        $(".banner").css({minHeight: 0 +"px" });    
    }
    
}
setBannerHeight();



// SET HOME INFO-BOX HEIGHT 
function setBoxElementHeight() {
    var boxHeight = $(".boxes").height();
    if($(window).width() > 767){ 
        $(".info-box").css({minHeight: boxHeight + "px"});  
    }
    else{
        $(".info-box").css({minHeight: 0});    
    }
}
setBoxElementHeight();



function setNavbarHeight() {
    var bannerHeight = $(".banner").height();
    window.addEventListener('scroll', function(e){
        var distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = bannerHeight,
            header = document.querySelector(".ha-waypoint");
        if (distanceY > shrinkOn) {
            classie.add(header,"smaller");
        } else {
            if (classie.has(header,"smaller")) {
                classie.remove(header,"smaller");
            }
        }
    });
}
setNavbarHeight();

/*** Retina Image Loader ***/

$("img").unveil();


$("html").niceScroll({
            zindex:999999,
            boxzoom:true,
            cursoropacitymin :0.5,
            cursoropacitymax :0.8,
            cursorwidth :"10px",
            cursorborder :"0px solid",
            autohidemode:false
        });



$('a[href*=#]:not([href=#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
            $('html,body').animate({
                scrollTop: target.offset().top
            }, 1000);
            return false;
        }
    }
});

$(window).scroll(function() {
    if ($(this).scrollTop()) {
        $('#up-btn').slideDown();
    } else {
        $('#up-btn').slideUp();
    }
});

$('#up-btn').click(function() {
    jQuery('html,body').animate({
        scrollTop: 0
    }, 500);
    return false;
});




var parallax = document.querySelectorAll(".parallax");

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};


if(isMobile.any())
{
    speed = 0.3;
}
else
{
    speed = 0.5;
}

if(isMobile.any())
{
    $('body').addClass('mobile');
}

if(isMobile.iOS())
{
    $('body').addClass('ios');
}


window.onscroll = function(){
    [].slice.call(parallax).forEach(function(el,i){

        var windowYOffset = window.pageYOffset,
            elBackgrounPos = "0px " + "-"+(windowYOffset * speed) + "px";

        el.style.backgroundPosition = elBackgrounPos;

    });
};